import React from 'react';
import { Box, Typography, } from '@mui/material';
import styled from '@emotion/styled';

const CopyrightNotice = () => {

  const StyledBox = styled(Box)(({ backgroundColor }) => ({
    position: 'fixed',
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: backgroundColor,
    padding: "4px 0",
    zIndex: 80,
  }));

  const StyledTypography = styled(Typography)({
    color: '#4d84e1',
    fontSize: '12px',
    fontFamily: 'Normal Light',
    letterSpacing: '3px',
    textAlign: 'center',
    userSelect: 'none',  // Add this line to make the text non-selectable
  });

  return (
    <>
      <StyledBox backgroundColor='#11111170'>
        <StyledTypography>
          <span>Confidential and Proprietary. </span>
          <span>Copyright © by Priority 6, Inc. All Rights Reserved.</span>
        </StyledTypography>
      </StyledBox >
    </>
  );
};

export default CopyrightNotice;
