// BackgroundVideo.js
import React from 'react';
import ReactDOM from 'react-dom';
import { Box } from '@mui/material';

const BackgroundVideo = ({ videoSrc, posterSrc }) => {
  return ReactDOM.createPortal(
    <Box
      sx={{
        width: '100vw',
        height: '100vh',
        position: 'fixed',
        overflow: 'hidden',
        top: 0,
        left: 0,
        zIndex: -1,
        '& video': {
          width: '100%',
          height: '100%',
          objectFit: 'cover',
        },
      }}
    >
      <video
        src={videoSrc}
        poster={posterSrc}
        disablePictureInPicture
        playsInline
        autoPlay
        loop
        volume={0}
        muted
      />
    </Box>,
    document.body
  );
};

export default BackgroundVideo;
